import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlus,
  faSliders,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

const DataColumns = ({
  parentFlowId,
  onAddChild,
  onEditStep,
  onEditAppFlow,
  deleteFlowStepDialog,
  fetchArgumentsForStep,
}) => [
  {
    title: 'Actions',
    width: '20px',
    cell: (props) => (
      <td>
        <div className='k-d-flex'>
          {props?.dataItem?.StepType === 'APPFLOW' && (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className='k-mr-2'
                onClick={() => onAddChild(props.dataItem)}
                style={{ cursor: 'pointer', color: '#4caf50' }}
              />
            </>
          )}
          <FontAwesomeIcon
            icon={faPencil}
            className='k-mr-2'
            onClick={() => onEditStep(props.dataItem)}
            style={{ cursor: 'pointer', color: '#000' }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            className='k-mr-2'
            onClick={() => deleteFlowStepDialog(props.dataItem)}
            style={{ cursor: 'pointer', color: '#f44336' }}
          />
        </div>
      </td>
    ),
  },
  {
    field: 'Arguments',
    title: 'Arguments',
    width: '10px',
    cell: (props) => {
      return (
        <td>
          <div className='k-d-block k-text-center'>
            <FontAwesomeIcon
              icon={faSliders}
              className='k-mr-2'
              onClick={() => fetchArgumentsForStep(props?.dataItem)}
              style={{ cursor: 'pointer', color: '#f44336' }}
            />
          </div>
        </td>
      );
    },
  },
  { field: 'UcOssiAfStepId', title: 'Step ID', expandable: true },
  {
    field: 'UcOssiAfId',
    title: 'Flow ID',
    cell: (props) => {
      const isSameAsParentFlow = parentFlowId === props?.dataItem?.UcOssiAfId;
      return (
        <td>
          <span
            onClick={
              !isSameAsParentFlow
                ? () => onEditAppFlow(props.dataItem)
                : undefined
            }
            className={`${!isSameAsParentFlow ? 'edit-link' : ''}`}
          >
            {props.dataItem.UcOssiAfId}
          </span>
        </td>
      );
    },
  },
  {
    field: 'StepType',
    title: 'Step Type',
    cell: (props) => {
      const getDotClass = (stepType) => {
        switch (stepType) {
          case 'APPFLOW':
            return 'approved';
          case 'DEFAFSTP':
            return 'info';
          case 'PREDEFLST':
            return 'new';
          default:
            return 'default'; // Fallback dot
        }
      };
      return (
        <td>
          <div className='k-d-flex'>
            <span className={`badge k-d-flex k-align-items-center`}>
              <span
                className={`circle ${getDotClass(props.dataItem.StepType)}`}
              />
            </span>
            {props?.dataItem?.StepTypeDisplayName}
          </div>
        </td>
      );
    },
  },
];

export default DataColumns;
