import React from 'react';
import {
  BasicContentSlider as ContentSlider,
  buildNotification,
  DescriptionTextArea,
  GenerateNotification,
  InputSwitch,
  isFromValid,
  isValidate,
  Loader,
  SliderCard,
  TextInput,
} from 'smart-react';
import {
  listFlows,
  updateFlow,
} from '../../../AppFlow/Services/AppFlowService';
import {
  findTenant,
  findTenantById,
  ValidatePermissions,
} from '../../../../../Utils/Auth/ValidatePermissions';
import { useAuth } from '../../../../Core/Context/AuthContext';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { Field, Form } from '@progress/kendo-react-form';
import TenantDropdown from '../../../../Core/Components/TenantDropdown/TenantDropdown';
import { Error } from '@progress/kendo-react-labels';
import CommandEditor from '../../../../Core/Components/CommandEditor/CommandEditor';
import { Button } from '@progress/kendo-react-buttons';
import {
  ERROR_MESSAGE,
  UPDATE_MESSAGE,
} from '../../../../../constants/notificationMessages';
import { APPFLOWS } from '../../../../../constants/applicationConstants';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../../constants/eventDataTypes';
import AppFlowSteps from '../../Pages/AppFlowSteps';

/**
 * limit to view recently selected items.
 */

/**
 * Lookup mode of test
 * Slider Card of Test lookup
 */
const AddAppFlowSlider = ({
  show,
  handleSlide,
  dataItem,
  setAddAppFlowSlideShow,
}) => {
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [isTextArea, setIsTextArea] = React.useState(false);
  const [tenant, setTenant] = React.useState(findTenantById(tenantID));
  const [loading, setLoading] = React.useState(false);
  const [isEditor, setIsEditor] = React.useState(false);
  const [validateForm, setValidateForm] = React.useState(false);
  const [formData, setFormData] = React.useState({
    tenant_id: tenantID,
    tenant_name: tenant?.TenantName,
    uc_ossi_prod_id: 'REDPRAIRIE',
    uc_ossi_af_id: '',
    uc_ossi_descr: '',
    uc_ossi_grp: '',
    uc_ossi_stop_on_error_flg: '',
    uc_ossi_cmd: '',
  });
  const requiredFields = ['uc_ossi_af_id', 'uc_ossi_descr', 'tenant_name'];
  const [editorField, setEditorField] = React.useState({
    name: '',
    value: '',
    label: '',
  });

  React.useEffect(() => {
    if (dataItem) {
      const { accessToken } = getTokensFromStorage();
      (async () => {
        setLoading(true);
        const response = await listFlows({
          accessToken: accessToken,
          limit: '50',
          offset: 1,
          filter: [
            {
              ColumnName: 'uc_ossi_af_id',
              Op: 'eq',
              ColumnValue: dataItem?.UcOssiAfId,
            },
          ],
          isServiceWorker: false,
        });
        const flowData = response.flows[0];
        setFormData(flowData);
        setLoading(false);
      })();
      setTenant(findTenant(dataItem));
      if (ValidatePermissions(dataItem)) {
        setDetailsDataModified(true);
      }
    }
  }, [dataItem]);

  /**
   * onSave
   * submit the Flow data to api
   */
  const onSave = async () => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      uc_ossi_stop_on_error_flg: formData?.uc_ossi_stop_on_error_flg ? 1 : 0,
    };
    let response;
    let method;
    method = updateFlow;
    notificationMetaData = UPDATE_MESSAGE;

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: APPFLOWS,
        isServiceWorker: false,
      });

      if (!response?.IsSuccess) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.errorMessage,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
      setAddAppFlowSlideShow(false);
    }
  };

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const children = (
    <SliderCard
      title={'Edit Application Flow'}
      handleSlide={handleSlide}
      className="eq-lookup large-slider"
    >
      <SliderCard.Body>
        <div className="container mx-auto">
          <React.Suspense fallback={<Loader />}>
            {loading && <Loader />}
            <>
              <div className={`${isEditor ? '' : 'k-d-none'}`}>
                <CommandEditor
                  onChange={handleInputChange}
                  label={'Syntax'}
                  renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                  validateForm={validateForm}
                  formData={formData}
                  setFormData={setFormData}
                  detailsDataModified={detailsDataModified}
                  isEditor={isEditor}
                  setIsEditor={setIsEditor}
                  editorField={editorField}
                  setEditorField={setEditorField}
                  smHeight={'100px'}
                  lgHeight={'70vh'}
                />
              </div>
              <div className={`${isTextArea ? '' : 'k-d-none'}`}>
                <DescriptionTextArea
                  onChange={handleInputChange}
                  label={'Description'}
                  renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                  validateForm={validateForm}
                  formData={formData}
                  setFormData={setFormData}
                  detailsDataModified={detailsDataModified}
                  isTextArea={isTextArea}
                  setIsTextArea={setIsTextArea}
                  editorField={editorField}
                  setEditorField={setEditorField}
                  row={2}
                />
              </div>
              <div
                className={`add-flow ${!isTextArea && !isEditor ? '' : 'k-d-none'}`}
              >
                <Card className="k-mt-3 ">
                  <CardBody className="detail-card-body">
                    <div>
                      <Form
                        onSubmit={onSave}
                        render={(formRenderProps) => (
                          <div className="card-content">
                            <div className="k-d-flex single-field-row single-row-60">
                              <div
                                className={`field-wrapper prefix-field-wrapper ${isValidate(validateForm, formData?.tenant_name) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <TenantDropdown
                                  textField="TenantName"
                                  label="Tenant"
                                  dataItemKey="TenantId"
                                  className="tenant-dropdown k-align-self-end"
                                  tenant={tenant}
                                  setTenant={setTenant}
                                  defaultItem={{
                                    TenantName: 'Select Tenant ...',
                                    disabled: true,
                                  }}
                                  editMode={'ReadOnly'}
                                  renderTypes={'ReadOnly'}
                                  filterable={true}
                                  tenantFormId="tenant_id"
                                  tenantFormName="TenantName"
                                  setFormData={setFormData}
                                  requiredField={true}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.tenant_name,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                            </div>
                            <div className="k-d-flex single-field-row single-row-100">
                              <div
                                className={`field-wrapper prefix-field-wrapper' ${isValidate(validateForm, formData?.uc_ossi_af_id) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <Field
                                  key={'uc_ossi_af_id'}
                                  id={'uc_ossi_af_id'}
                                  name={'uc_ossi_af_id'}
                                  label={'Application Flow ID'}
                                  data={formData?.uc_ossi_af_id}
                                  onChange={handleInputChange}
                                  type={'text'}
                                  component={TextInput}
                                  renderTypes={'ReadOnly'}
                                  optional={false}
                                  requiredField={true}
                                  prefix={true}
                                  prefixLabel={tenant?.OrganizationPrefix}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.uc_ossi_af_id,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                              <div className={`field-wrapper`}>
                                <Field
                                  key={'uc_ossi_grp'}
                                  id={'uc_ossi_grp'}
                                  name={'uc_ossi_grp'}
                                  data={formData?.uc_ossi_grp}
                                  onChange={handleInputChange}
                                  label={'Group Name'}
                                  type={'text'}
                                  component={TextInput}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  optional={false}
                                  requiredField={false}
                                />
                              </div>
                            </div>
                            <div className="k-d-flex single-field-row single-row-100">
                              <div
                                className={`field-wrapper field-wrapper-description field-wrapper-100 ${isValidate(validateForm, formData?.uc_ossi_descr) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <DescriptionTextArea
                                  onChange={handleInputChange}
                                  label={'Description'}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  validateForm={validateForm}
                                  formData={formData}
                                  setFormData={setFormData}
                                  detailsDataModified={detailsDataModified}
                                  isTextArea={isTextArea}
                                  setIsTextArea={setIsTextArea}
                                  editorField={{
                                    name: 'uc_ossi_descr',
                                    value: formData?.uc_ossi_descr,
                                    label: 'Description',
                                  }}
                                  setEditorField={setEditorField}
                                  row={2}
                                  requiredField={true}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.uc_ossi_descr,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                            </div>
                            <div className="k-d-flex single-field-row single-row-100">
                              <div
                                className={`field-wrapper field-wrapper-100`}
                              >
                                <CommandEditor
                                  onChange={handleInputChange}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  validateForm={validateForm}
                                  formData={formData}
                                  setFormData={setFormData}
                                  detailsDataModified={detailsDataModified}
                                  isEditor={isEditor}
                                  setIsEditor={setIsEditor}
                                  editorField={{
                                    name: 'uc_ossi_cmd',
                                    value: formData?.uc_ossi_cmd,
                                    label: 'Command',
                                  }}
                                  setEditorField={setEditorField}
                                  smHeight={'100px'}
                                  lgHeight={'70vh'}
                                />
                              </div>
                            </div>
                            <div className="single-field-row k-mt-4 single-row-60">
                              <div className={`field-wrapper`}>
                                <Field
                                  name="uc_ossi_stop_on_error_flg"
                                  component={InputSwitch}
                                  label="Stop on Error?"
                                  onLabel="Yes"
                                  offLabel="No"
                                  id="uc_ossi_stop_on_error_flg"
                                  checked={formData?.uc_ossi_stop_on_error_flg}
                                  onChange={handleInputChange}
                                  disabled={!detailsDataModified}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                    {formData?.uc_ossi_af_id && (
                      <AppFlowSteps flow={formData} />
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          </React.Suspense>
        </div>
      </SliderCard.Body>
      <SliderCard.Footer>
        <>
          <Button
            className={'large-slider-action'}
            icon="cancel"
            onClick={() => setAddAppFlowSlideShow(false)}
            type="button"
          >
            Cancel
          </Button>
          <Button themeColor="primary" icon="save" onClick={onSave}>
            Save
          </Button>
        </>
      </SliderCard.Footer>
    </SliderCard>
  );

  return (
    <div className="lookup-mode af-lookup">
      <ContentSlider show={show} children={children} />
    </div>
  );
};

export default AddAppFlowSlider;
